
import { reactive } from "vue"
import TabsComponent from "../navigation/TabsComponent.vue"

export default {
  components: { TabsComponent },
  setup() {
    const tabItems = reactive([
      // {label: 'Computer', to: {name: 'computerProfile' }},
      { label: "Releases", routeName: "runConfigCollection" },
      { label: "Frontend", routeName: "frontendCollection" },
      { label: "Backend", routeName: "backendCollection" },
      { label: "Desktop", routeName: "aqsCollection" },
      { label: "Halapi", routeName: "halapiCollection" },
      { label: "Desktop (32-bit)", routeName: "aqs32Collection" },
      { label: "Toolkit", routeName: "toolkitCollection" },
      { label: "James", routeName: "jamesCollection" },
    ])
    return {
      tabItems,
    }
  },
}
